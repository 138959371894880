import { CloseCircle20Px } from "@locaisolutions/icons";
import { Box, Button, Paper, Skeleton, Stack } from "@mui/material";

import { useToast } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useConfirmInventoryMovementMutation } from "~/redux/warehouse/autostoreBinMaintenance.hooks";
import {
  useCloseBinMutation,
  useDeleteBinFlagMutation,
  usePostSkipInventoryMovementMutation,
  useRequestNextBinMutation
} from "~/redux/warehouse/autostoreGrid.hooks";
import { useGetInventoryByAutostoreBinNumberQuery } from "~/redux/warehouse/inventory.hooks";

import { BinNotEmptyButton } from "./BinNotEmptyButton";
import { FlagBinButton } from "./FlagBinButton";
import { MoveInventoryButton } from "./MoveInventoryButton";
import { PulsingArrows } from "./PulsingArrows";
import { UnflagBinButton } from "./UnflagBinButton";
import {
  setSourceDestinationBins,
  setTaskState
} from "./binMaintenanceWorkstation.slice";

export const BinMaintenancePort = ({
  portId,
  isLoading
}: {
  portId: number;
  isLoading: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();

  const [requestNextBin] = useRequestNextBinMutation();
  const [deleteBinFlag] = useDeleteBinFlagMutation();
  const [skipInventoryMovement] = usePostSkipInventoryMovementMutation();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const { movementTaskState, movementData } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );

  const {
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    binState,
    refetch,
    ...portStatus
  } = usePortStatusQuery(portId);

  const [
    confirmInventoryMovement,
    { isLoading: confirmInventoryMovementIsLoading }
  ] = useConfirmInventoryMovementMutation();
  const [closeBin] = useCloseBinMutation();

  const cleaningBin = movementTaskState === "CleaningBin"; // TODO: how to hanlde multiple cleaning bins at workstation?
  const movingProduct = movementTaskState === "MovingProduct";
  const confirmingMovement = movementTaskState === "ConfirmingMovement";
  const { selectedBin: thisBinNumber, isReady } = portStatus;
  const { sourceBin, destinationBin } = movementData?.inventoryMovements || {};
  const isTheCleaningBin = movementData?.binCleaningPorts?.includes(portId);
  const isSourceBin = !!(
    sourceBin &&
    thisBinNumber &&
    thisBinNumber === sourceBin.autostoreBinId
  );
  const isDestinationBin = !!(
    destinationBin &&
    thisBinNumber &&
    thisBinNumber === destinationBin.autostoreBinId
  );

  const { data: binInventory, refetch: refetchBinInventory } =
    useGetInventoryByAutostoreBinNumberQuery(
      autostoreGridId &&
        (isSourceBin || (isDestinationBin && confirmingMovement))
        ? {
            autostoreGridId,
            binNumber: thisBinNumber
          }
        : skipToken
    );

  const sourceCompartmentNumbersWithQty =
    binInventory &&
    ((isSourceBin && movingProduct) || (isDestinationBin && confirmingMovement))
      ? binInventory.map((inventory) => ({
          autostoreCompartmentNumber: inventory.bin.autostoreCompartmentNumber,
          quantity: inventory.count.value
        }))
      : undefined;

  const moveButtonDisabled = !movingProduct || !isReady;

  const makeNextBinRequest = useCallback(
    async (requestingPortId: number) => {
      if (!autostoreGridId) return;
      try {
        await requestNextBin({
          autostoreGridId,
          portId: requestingPortId
        }).unwrap();
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
      }
    },
    [autostoreGridId, requestNextBin, errorToast]
  );

  const getNextInventoryMovement = async () => {
    if (
      !autostoreGridId ||
      !sourceBin ||
      !destinationBin ||
      !selectedWorkstation
    )
      return;
    try {
      const nextInventoryMovement = await confirmInventoryMovement({
        autostoreGridId,
        workstationId: selectedWorkstation.id,
        sourceBinId: sourceBin.autostoreBinId,
        destinationBinId: destinationBin.autostoreBinId
      }).unwrap();
      dispatch(setSourceDestinationBins(nextInventoryMovement));
      dispatch(setTaskState("MovingProduct"));
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const cancelInventoryMovement = async () => {
    if (!autostoreGridId || !sourceBin || !selectedWorkstation) return;
    try {
      binState?.flags.forEach((flag) => {
        void deleteBinFlag({
          autostoreGridId,
          binNumber: sourceBin.autostoreBinId,
          reason: flag,
          workstationId: selectedWorkstation.id
        });
      });
      const nextBins = await skipInventoryMovement({
        autostoreGridId,
        workstationId: selectedWorkstation.id
      }).unwrap();
      dispatch(setSourceDestinationBins(nextBins));
      dispatch(setTaskState("MovingProduct"));
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  // request next bin for cleaning port
  useEffect(() => {
    if (isTheCleaningBin && portStatus.mode === "OPEN")
      void makeNextBinRequest(portId);
  }, [isTheCleaningBin, portId, makeNextBinRequest, portStatus.mode]);

  // refetch port/bin info when source/destination bin changes
  useEffect(() => {
    if (sourceBin?.autostoreBinId || destinationBin?.autostoreBinId) {
      refetch().catch((error) => {
        errorToast(getMessageFromRtkError(error));
      });
    }
  }, [
    sourceBin?.autostoreBinId,
    destinationBin?.autostoreBinId,
    refetch,
    errorToast
  ]);

  // highlight first bin to arrive at workstation
  useEffect(() => {
    if (!movementTaskState && isReady) {
      if (isTheCleaningBin) dispatch(setTaskState("CleaningBin"));
      else if (isSourceBin) dispatch(setTaskState("MovingProduct"));
    }
  }, [movementTaskState, dispatch, isTheCleaningBin, isSourceBin, isReady]);

  return (
    <Stack direction="row" flex={1} position="relative">
      <Stack width={"100%"}>
        {!error && (
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                padding: 1,
                zIndex: 2,
                borderRadius: "0.5em",
                border: `0.625em solid`,
                borderColor:
                  (isTheCleaningBin && cleaningBin) ||
                  (isSourceBin && (movingProduct || confirmingMovement))
                    ? "primary.main"
                    : "transparent",
                animation:
                  isDestinationBin && confirmingMovement
                    ? "slide-in 750ms"
                    : "none",
                "@keyframes slide-in": {
                  from: {
                    transform: "translateX(-100%)"
                  },
                  to: {
                    transform: "translateX(0%)"
                  }
                }
              }}
              data-testid="bin-button"
              role="button"
              onClick={() => {
                if (confirmingMovement) return; // dont allow user to switch bins while confirming movement
                if (isTheCleaningBin) {
                  dispatch(setTaskState("CleaningBin"));
                } else if (isSourceBin) {
                  dispatch(setTaskState("MovingProduct"));
                }
              }}
            />
            <Paper
              role="button"
              sx={{
                backgroundColor:
                  (isSourceBin && movingProduct) ||
                  (isDestinationBin && confirmingMovement)
                    ? "background.lightBlue"
                    : "background.gray",
                p: 2,
                border: "none"
              }}
            >
              <AutostoreBin
                state={isLoading ? "Waiting for Bin" : activityState}
                binId={thisBinNumber}
                pickQuantity={""}
                pickCompartment={null}
                numberOfRows={horizontalCompartmentCount ?? 1}
                numberOfColumns={verticalCompartmentCount ?? 1}
                compartmentNumberWithQuantities={
                  (isSourceBin && movingProduct) ||
                  (isDestinationBin && confirmingMovement)
                    ? sourceCompartmentNumbersWithQty
                    : undefined
                }
                activeTaskGroupCompartments={
                  sourceCompartmentNumbersWithQty?.map((c, i) =>
                    c.autostoreCompartmentNumber
                      ? c.autostoreCompartmentNumber - 1
                      : i
                  ) ?? []
                }
              />
            </Paper>
          </Box>
        )}

        {/* Sets of buttons depnding on type of bin (cleaning, source, destination) */}
        {isSourceBin && (
          <Stack direction="row" justifyContent="center" spacing={2} my={5}>
            {confirmingMovement ? (
              <UnflagBinButton
                binState={binState}
                makeNextBinRequest={getNextInventoryMovement}
                portId={portId}
                disabled={
                  !isReady ||
                  !confirmingMovement ||
                  confirmInventoryMovementIsLoading
                }
              />
            ) : (
              <>
                <Button
                  variant="subtle"
                  size="large"
                  startIcon={<CloseCircle20Px />}
                  onClick={() => cancelInventoryMovement()}
                >
                  Cancel Movement
                </Button>
                {binInventory && (
                  <MoveInventoryButton
                    sourceBinInventory={binInventory}
                    disabled={moveButtonDisabled}
                    portId={portId}
                    refetchBinInventory={refetchBinInventory}
                  />
                )}
              </>
            )}
          </Stack>
        )}
        {isDestinationBin &&
          !confirmingMovement && ( // dont show buttons if confirming movement
            <Stack direction="row" justifyContent="center" spacing={2} my={5}>
              <BinNotEmptyButton
                state={activityState}
                binId={thisBinNumber}
                numberOfRows={horizontalCompartmentCount ?? 1}
                numberOfColumns={verticalCompartmentCount ?? 1}
                portId={portId}
                binState={binState}
              />
              <FlagBinButton portId={portId} disabled={!isReady} />
            </Stack>
          )}
        {!!thisBinNumber && isTheCleaningBin && (
          <Stack direction="row" justifyContent="center" spacing={2} my={5}>
            <UnflagBinButton
              binState={binState}
              makeNextBinRequest={async (portId) => {
                if (thisBinNumber && autostoreGridId)
                  await closeBin({
                    autostoreGridId,
                    portId,
                    binNumber: thisBinNumber
                  });
                await makeNextBinRequest(portId);
              }}
              portId={portId}
              disabled={!isReady || !cleaningBin}
            />
          </Stack>
        )}
        {isLoading && (
          <Stack direction="row" justifyContent="center" spacing={2} my={5}>
            <Skeleton height={"40px"} width={"150px"} variant="rounded" />
            <Skeleton height={"40px"} width={"150px"} variant="rounded" />
          </Stack>
        )}
      </Stack>
      {isDestinationBin && (
        <Box position="absolute" top="90px" left="-85px">
          <PulsingArrows />
        </Box>
      )}
    </Stack>
  );
};
