import { Grid } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

import { useAppSelector } from "~/app/store";
import { ternaryIff } from "~/lib/helpers";

import {
  selectPickingState,
  selectCurrentPick
} from "~/redux/selectors/autostoreSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { useAddedTotes } from "./hooks/useAddedTotes";
import AutostoreTote from "./tote/AutostoreTote";

type AutostorePickingTotesProps = {
  handleToteClick: (args: {
    toteId: string;
    pickId: string;
    reporter?: string;
  }) => Promise<void>;
};

export const AutostorePickingTotes = ({
  handleToteClick
}: AutostorePickingTotesProps) => {
  const { ap_fusionPortScreenEnabled, ap_totesPlacement: totesPlacement } =
    useAppSelector(selectClientConfig);
  const pickingState = useAppSelector(selectPickingState);
  const currentPick = useAppSelector(selectCurrentPick);
  const { originalTotes, addedTotes } = useAddedTotes();

  const totesRowGapStyle = ap_fusionPortScreenEnabled
    ? { marginTop: "20px" }
    : { marginTop: "40px", rowGap: "200px" };

  return (
    <Grid
      container
      item
      direction="row"
      alignItems="flex-start"
      xs={totesPlacement === "split" ? 12 : 8}
      sx={totesRowGapStyle}
    >
      {pickingState &&
        originalTotes.map((tote, i) => {
          const allTotesAtPosition = addedTotes
            // select all added totes that are in our position
            .filter((addedTote) => addedTote.oldToteId === tote.toteId)
            .map((at) => ({
              toteId: at.toteId,
              externalToteId: at.externalToteId
            }));

          // add the original tote at the back
          allTotesAtPosition.unshift({
            toteId: tote.toteId,
            externalToteId: tote.externalToteId
          });

          // move the currently assigned tote to the front of the tote stack.
          allTotesAtPosition.sort((a, b) => {
            if (currentPick?.assignedToteId === a.toteId) {
              return 1;
            }
            if (currentPick?.assignedToteId === b.toteId) {
              return -1;
            }
            return 0;
          });

          const pickClickCallback = async () => {
            if (!currentPick || !currentPick.assignedToteId) return;
            await handleToteClick({
              toteId: currentPick.assignedToteId,
              pickId: currentPick.pickId
            });
          };

          return (
            <Fragment key={i}>
              <Grid
                item
                xs={totesPlacement === "split" ? 4 : 3}
                key={i}
                style={{
                  width: "100%",
                  marginBottom: 0,
                  position: "relative",
                  left: `${
                    ap_fusionPortScreenEnabled
                      ? ternaryIff(i % 2 === 0, "-4%", "4%")
                      : 0
                  }`,
                  minHeight: ap_fusionPortScreenEnabled ? "300px" : "370px"
                }}
              >
                <Grid
                  item
                  style={{
                    position: "relative"
                  }}
                >
                  <Grid container>
                    {allTotesAtPosition.map((currentTote, index) => {
                      const isActiveTote =
                        currentTote.toteId === currentPick?.assignedToteId;
                      const leftPosition = 50 + index;
                      const topPosition = index * 2;

                      return (
                        <Grid
                          key={`toteId-${currentTote.toteId}`}
                          item
                          style={{
                            position: "absolute",
                            left: `${leftPosition}%`,
                            marginTop: `${topPosition}%`,
                            transform: "translateX(-50%)",
                            width: "100%"
                          }}
                        >
                          <AutostoreTote
                            toteId={currentTote.toteId}
                            position={i + 1}
                            externalToteId={currentTote.externalToteId}
                            isActiveTote={isActiveTote}
                            onClickCb={pickClickCallback}
                            isLastStackedTote={
                              index === allTotesAtPosition.length - 1
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
              {/* create space for bin/product column */}
              {totesPlacement === "split" && (i + 1) % 2 === 1 && (
                <Grid item xs={4} />
              )}
            </Fragment>
          );
        })}
    </Grid>
  );
};
