import { Bin20Px, BookmarkFill20Px, Zone20Px } from "@locaisolutions/icons";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  Container,
  Dialog,
  Stack,
  SvgIcon,
  styled
} from "@mui/material";
import {
  AlertBanner,
  ErrorPanel,
  ProductCard,
  ScanningIndicator,
  useScanIndicator,
  useToast
} from "@qubit/autoparts";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import MultiPort from "~/features/autostoreBin/MultiPort";
import { useInactivityResetTimer } from "~/hooks/useInactivityResetTimer";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import usePortStatus from "~/hooks/usePortStatus";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";

import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import {
  checkIsExpiration,
  getBarcodeValue,
  searchProduct
} from "~/lib/helpers";
import { useGridV2Subscription } from "~/lib/signalr";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";

import {
  nextEmptyBin,
  fetchPortStatus,
  resetPortBinData
} from "~/redux/actions";
import { setCurrentEmptyBin } from "~/redux/actions/autostore";
import { clearSelectedVariant } from "~/redux/actions/inventory";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import {
  selectIsInventoryAtPort,
  selectIsInventoryDateValid,
  selectSelectedRow
} from "~/redux/selectors/autostorePutawaySelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import {
  AutostoreEvent,
  NextEmptyBinResponse,
  PutAwayTaskSummaryDto
} from "~/types/api";

import { AutostorePutawaySearch } from "./AutostorePutawaySearch";
import { BinQuantityWidget } from "./BinQuantityWidget";
import { ConfirmInductionButton } from "./ConfirmInductionButton";
import { GetNewBinButton } from "./GetNewBinButton";
import InventoryDateField from "./InventoryDateField";
import LotNumberField from "./LotNumberField";
import { PutawayTasksTable } from "./PutawayTasksTable";
import { QuantityField } from "./QuantityField";
import {
  selectRow,
  setChangedQuantity,
  setInventoryDate,
  setSearchData,
  setIsPutawayTaskTableRefreshed,
  setPortPollingActive,
  setClosePortBtnDisabled,
  selectNewCompartment,
  reset,
  setOnLeaveInputTextShown,
  setLotNumber,
  selectIsLotNumberFieldFocused,
  selectCompartment,
  selectShouldShowGetNewBinButton,
  selectShouldShowMaxQtyWarningBanner,
  setHasUserChangedBin,
  setShouldShowGetNewBinButton
} from "./autostorePutaway.slice";
import { AdjustQuantityModal } from "./modals/AdjustQuantityModal";
import BinNotEmptyCompartment from "./modals/BinNotEmptyCompartment";
import { BinNotEmptyModal } from "./modals/BinNotEmptyModal";
import ChangeSuggestedBinModal from "./modals/ChangeSuggestedBinModal";
import FlagBinModal from "./modals/FlagBinModal";
import InventoryHoldModal from "./modals/InventoryHoldModal";
import { useCubing } from "./useCubing";
import { useGetPutawayTasks } from "./useGetPutawayTasks";
import { useStartInduction } from "./useStartInduction";

const ProductFieldsContainer = styled(Box)`
  margin-top: 18px;
  display: grid;
  grid-template-columns: 210px 1fr;
  gap: 16px;
  align-items: center;
`;

type Props = {
  viewTitle: ViewNameTranslation;
};

export const AutostorePutawayV2 = (props: Props) => {
  const { viewTitle } = props;

  /** response from nextEmptyBin call */
  const nextEmptyBinByPort = useAppSelector(
    (state) => state.autostore.nextEmptyBinByPort
  );
  const portStateByPort = useAppSelector(
    (state) => state.autostore.portStateByPort
  );
  const siteAllPortIds = useAppSelector(
    (state) => state.workstations.siteAllPortIds
  );
  const currentSelectedBin = useAppSelector(
    (state) => state.autostore.currentEmptyBin
  );

  const dispatch = useAppDispatch();
  const { errorToast, successToast } = useToast();

  const currentSelectedPortId = currentSelectedBin?.openBinResponse.portId;
  const currentSelectedBinId = currentSelectedBin?.openBinResponse.binId;
  const selectedRowId = useAppSelector(
    (state) => state.autostorePutaway.selectedRowId
  );
  const selectedCompartment = useAppSelector(
    (state) => state.autostorePutaway.selectedCompartment
  );
  const newSelectedCompartment = useAppSelector(
    (state) => state.autostorePutaway.newSelectedCompartment
  );

  const inventoryDate = useAppSelector(
    (state) => state.autostorePutaway.inventoryDate
  );
  const searchData = useAppSelector(
    (state) => state.autostorePutaway.searchData
  );
  const isPutawayTaskTableRefreshed = useAppSelector(
    (state) => state.autostorePutaway.isPutawayTaskTableRefreshed
  );
  const portPollingActive = useAppSelector(
    (state) => state.autostorePutaway.portPollingActive
  );
  const isLotNumberFieldFocused = useAppSelector(selectIsLotNumberFieldFocused);
  const shouldShowGetNewBinButton = useAppSelector(
    selectShouldShowGetNewBinButton
  );
  const shouldShowMaxQtyWarningBanner = useAppSelector(
    selectShouldShowMaxQtyWarningBanner
  );

  const pageLimit = 7;
  const { putawayTasks, putawayTasksLoading } = useGetPutawayTasks(
    pageLimit,
    searchData
  );
  const selectedRow = useAppSelector((state) =>
    selectSelectedRow(state, putawayTasks)
  );

  const { cubingData, selectedBinCubingData } = useCubing({
    selectedRow
  });

  const rowHasDate =
    selectedRow?.expirationDate || selectedRow?.manufactureDate;
  const hasDateBeenRemoved = !inventoryDate && rowHasDate;
  const isInventoryDateValid =
    useAppSelector((state) =>
      selectIsInventoryDateValid(state, putawayTasks)
    ) && !hasDateBeenRemoved;

  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const clientId = useAppSelector(selectUsersClientId);
  const fulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const binFlaggingEnabled = fulfillmentCenter?.binFlaggingEnabled;
  const { inv_inventoryDateLabel, putaway_multipleSearchTerms } =
    useAppSelector(selectClientConfig);

  // Hooks
  const { t } = useTranslation();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  useKeyDownHandler();
  const { areAllPortsReady, areSomePortsReady } = usePortStatus(
    portStateByPort,
    siteAllPortIds,
    currentSelectedPortId
  );
  const [scanState, setScanState] = useScanIndicator();

  const hasNextEmptyBinCallCompleted =
    Object.keys(nextEmptyBinByPort).length >= 1;
  const isPlaceHoldEnabled =
    useAppSelector(selectIsInventoryAtPort) &&
    hasNextEmptyBinCallCompleted &&
    siteWorkstation;

  // Local State - Modals
  const [changeSuggestedBinModalOpen, setChangeSuggestedBinModalOpen] =
    useState(false);
  const [
    isBinNotEmptyCompartmentPanelOpen,
    setIsBinNotEmptyCompartmentPanelOpen
  ] = useState(false);
  const [isBinNotEmptyPanelOpen, setIsBinNotEmptyPanelOpen] = useState(false);
  const [isInventoryHoldModalOpen, setIsInventoryHoldModalOpen] =
    useState(false);
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);
  const [isUnderReceivedModalOpen, setIsUnderReceivedModalOpen] =
    useState(false);
  const [isOverReceivedModalOpen, setIsOverReceivedModalOpen] = useState(false);

  // Local State
  const [newSelectedBin, setNewSelectedBin] =
    useState<NextEmptyBinResponse | null>(null);
  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);
  const [lastScannedBarcode, setLastScannedBarcode] = useState<string | null>(
    null
  );

  const shouldShowActionButtons =
    putawayTasksLoading || putawayTasks.length >= 0;

  const onInactivityModalClose = useCallback(() => {
    setIsBinNotEmptyCompartmentPanelOpen(false);
    dispatch(reset());
    dispatch(setClosePortBtnDisabled(true));
    dispatch(setPortPollingActive(false));
    if (shouldListenToGridEvents) {
      setBinAtPortSeconds(0);
    }
  }, [dispatch, shouldListenToGridEvents]);

  const { restartInactivityTimer } = useInactivityResetTimer({
    onInactivityModalClose
  });

  const handleBarcodeScan = (barcode: string) => {
    // if client expects to scan PO and product for one search (search within a search)
    if (putaway_multipleSearchTerms) {
      // if there are already 2 values in the search or the current search has no results, clear and set to new barcode value
      const newBarcodeArray =
        searchData.scannedBarcodes.length > 1 || putawayTasks.length === 0
          ? [barcode]
          : [...searchData.scannedBarcodes, barcode];
      dispatch(
        setSearchData({
          scannedBarcodes: newBarcodeArray,
          offset: 1
        })
      );
    } else {
      dispatch(
        setSearchData({
          scannedBarcodes: [barcode],
          offset: 1
        })
      );
    }
  };

  useBarcodeScanner<boolean>({
    findScanMatch: (buffer: string) => {
      setLastScannedBarcode(buffer);
      const barcodeValue = getBarcodeValue(buffer);
      handleBarcodeScan(barcodeValue);
      setScanState("success");
      restartInactivityTimer();
      return true;
    },
    disabled: isBinNotEmptyPanelOpen || isLotNumberFieldFocused
  });

  // Clear port and bin state on first render
  useEffect(() => {
    dispatch(resetPortBinData());
    dispatch(
      setSearchData({
        scannedBarcodes: [],
        offset: 1
      })
    );
    setLastScannedBarcode(null);
    dispatch(selectRow(null));
    dispatch(setIsPutawayTaskTableRefreshed(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // After scanning barcode, if there are no putaway tasks returned, check backend products.
  // If product doesn't exist display an error.
  useEffect(() => {
    if (
      putawayTasks.length === 0 &&
      searchData.scannedBarcodes.length &&
      clientId
    ) {
      const productSearchFunc = async () => {
        const hits = await searchProduct(searchData.scannedBarcodes);
        if (!hits.length) {
          errorToast(t("scanned product does not exist"));
        }
      };
      void productSearchFunc();
    }
  }, [putawayTasks.length, searchData, clientId, errorToast, t]);

  const handleFetchPortStatus = async (portId?: number) => {
    await dispatch(fetchPortStatus({ portId }));
  };

  const { isStartInductionError } = useStartInduction();

  // Port polling: if log publisher enabled, poll port and log publisher state
  const waitInterval = 7;
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > waitInterval && siteWorkstation) {
        await Promise.all(
          siteWorkstation.ports.map((port) => {
            if (!portStateByPort[port.portId]?.getPortResponse.isReady)
              return handleFetchPortStatus(port.portId);

            return Promise.resolve();
          })
        );
      }
    },
    1000,
    !areAllPortsReady && shouldListenToGridEvents && !isStartInductionError
  );

  // If port polling active but log publisher not active for grid, poll port every half second
  usePromiseInterval(
    async () => {
      await Promise.all(
        (siteWorkstation?.ports ?? []).map((port) =>
          handleFetchPortStatus(port.portId)
        )
      );
    },
    500,
    portPollingActive && !shouldListenToGridEvents && !isStartInductionError
  );

  // Reset binAtPortSeconds, when bin arrives at port
  useEffect(() => {
    if (areAllPortsReady && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areAllPortsReady]);

  // Stop polling when bin arrives
  useEffect(() => {
    if (!shouldListenToGridEvents && areAllPortsReady) {
      dispatch(setPortPollingActive(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portStateByPort]);

  const abortRef = useRef(() => {});
  useEffect(() => () => abortRef.current(), []);

  const onGetNewBinSuccess = () => {
    dispatch(setShouldShowGetNewBinButton(false));
    dispatch(warehouseApi.util.invalidateTags(["cubing data"]));
    successToast(t("new bin requested"));
  };

  const onBinNotEmptySuccess = async () => {
    await dispatch(
      nextEmptyBin({ portId: newSelectedBin?.openBinResponse?.portId })
    );
    await dispatch(
      fetchPortStatus({ portId: newSelectedBin?.openBinResponse?.portId })
    );
    dispatch(warehouseApi.util.invalidateTags(["cubing data"]));
  };

  // If there's only one putaway default to that task
  useEffect(() => {
    if (putawayTasks.length === 1) {
      dispatch(selectRow(putawayTasks[0].putAwayTaskId));
    } else {
      dispatch(selectRow(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayTasks]);

  // Set lotNumber and inventory date based on selected row
  useEffect(() => {
    dispatch(setLotNumber(selectedRow?.lotNumber || ""));

    if (checkIsExpiration(inv_inventoryDateLabel)) {
      dispatch(
        setInventoryDate(
          selectedRow?.expirationDate ? selectedRow.expirationDate : null
        )
      );
    } else {
      dispatch(
        setInventoryDate(
          selectedRow?.manufactureDate ? selectedRow.manufactureDate : null
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  // Enable/disable showing text in search bar
  useEffect(() => {
    dispatch(setOnLeaveInputTextShown(!isPutawayTaskTableRefreshed));
  }, [dispatch, isPutawayTaskTableRefreshed]);

  // Clear selected row
  useEffect(() => {
    if (putawayTasks.length === 0 && !putawayTasksLoading) {
      dispatch(selectRow(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putawayTasks.length]);

  const gridSub = (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") {
      return;
    }

    if (
      data.event.gridId === siteWorkstation?.autostoreGridId &&
      !!data.event.portId &&
      siteAllPortIds.includes(data.event.portId)
    ) {
      // if "binOpened" event is received, trigger port polling
      setBinAtPortSeconds((time) => time + waitInterval);
    }
  };
  useGridV2Subscription(gridSub);

  const handleClickBinNotEmptyButton = () => {
    if (currentSelectedBin) {
      Sentry.captureMessage(
        `Bin not empty was clicked on bin number ${currentSelectedBin.openBinResponse.binId}`,
        "info"
      );
      if (shouldListenToGridEvents) {
        setBinAtPortSeconds(0);
      }
    }
    setIsBinNotEmptyCompartmentPanelOpen(true);
  };

  // Handle setting initial quantity value
  useEffect(() => {
    if (!selectedRow?.decantingRate) {
      dispatch(setChangedQuantity(undefined));
    } else {
      const decantRate = selectedRow?.decantingRate?.value || Number.MAX_VALUE;
      const remaining = selectedRow?.remaining.value || 1;
      dispatch(setChangedQuantity(Math.min(decantRate, remaining)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowId]);

  const handleClickPlaceInventoryHold = () => {
    setIsInventoryHoldModalOpen((prev) => !prev);
  };

  const handleClickFlagBin = () => {
    setIsFlagBinModalOpen((prev) => !prev);
  };

  const onConfirmChangeSuggestedBin = (newBin: NextEmptyBinResponse) => {
    dispatch(setHasUserChangedBin(true));
    dispatch(setCurrentEmptyBin(newBin));

    const compartment = cubingData?.find(
      (bin) => bin.binNumber === newBin.openBinResponse.binId
    )?.suggestedCompartment;
    if (compartment) {
      dispatch(selectCompartment(compartment - 1));
    }
  };

  const handleAdjustQuantityModalSuccess = () => {
    setIsUnderReceivedModalOpen(false);
    setIsOverReceivedModalOpen(false);
    dispatch(selectRow(null));
  };

  const handleSelectRow = (row: PutAwayTaskSummaryDto) => {
    if (row.putAwayTaskId !== selectedRowId) {
      dispatch(selectRow(row.putAwayTaskId));
    }
  };

  const onUnderReceivedClick = () => {
    setIsUnderReceivedModalOpen(true);
  };

  const onOverReceivedClick = () => {
    setIsOverReceivedModalOpen(true);
  };

  const AutostoreBinView = (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        gridColumn: "span 2"
      }}
    >
      <BinQuantityWidget />
      <MultiPort
        pickQuantity={0}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        currentSelectedBinId={selectedRowId ? currentSelectedBinId : undefined}
        selectedCompartment={selectedCompartment}
        showLoading={!areSomePortsReady && !isStartInductionError}
        enableBinFullWarning
      />

      {/* Bin related buttons (top-right of page) */}
      {shouldShowActionButtons && (
        <Stack justifyContent="space-evenly">
          <Button
            data-testid="change-selected-bin-button"
            variant="subtle"
            size="large"
            onClick={() => {
              setChangeSuggestedBinModalOpen(true);
            }}
            disabled={!areSomePortsReady || !selectedRowId}
            sx={{ minWidth: "245px" }}
            startIcon={<SvgIcon viewBox="0 0 20 20" component={Zone20Px} />}
          >
            {t("change selected bin")}
          </Button>
          <Button
            data-testid="bin-not-empty-button"
            variant="subtle"
            size="large"
            onClick={handleClickBinNotEmptyButton}
            disabled={!areSomePortsReady || isBinNotEmptyCompartmentPanelOpen}
            sx={{ minWidth: "245px" }}
            startIcon={<SvgIcon viewBox="0 0 20 20" component={Bin20Px} />}
          >
            {t("bin not empty")}
          </Button>
          {binFlaggingEnabled && (
            <Button
              data-testid="flag-bin-button"
              variant="subtle"
              size="large"
              onClick={handleClickFlagBin}
              disabled={!areSomePortsReady}
              sx={{ minWidth: "245px" }}
              startIcon={
                <SvgIcon viewBox="0 0 20 20" component={BookmarkFill20Px} />
              }
            >
              {t("flag bin")}
            </Button>
          )}
        </Stack>
      )}
    </Box>
  );

  const { setMenuItems } = useNavbar({
    centerComponent: useMemo(() => <AutostorePutawaySearch />, []),
    viewTitle
  });

  useEffect(() => {
    setMenuItems([
      {
        textContent: "Refresh",
        actionCb: () => {
          dispatch(setIsPutawayTaskTableRefreshed(true));
          setSearchData({
            scannedBarcodes: [],
            offset: 1
          });
          dispatch(selectRow(null));
        }
      }
    ]);
  }, [dispatch, setMenuItems]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "grid",
        gridTemplateRows: "285px minmax(620px, 1fr) 75px",
        gridTemplateColumns: "1fr 0.40fr",
        height: "calc(100vh - 56px)",
        gap: 2,
        pb: 2
      }}
    >
      {siteWorkstation ? (
        AutostoreBinView
      ) : (
        <ErrorPanel
          sx={{ gridColumn: "span 2" }}
          message={t("no autostore port selected")}
          mx={3}
        />
      )}
      {/* Table and PickInfo */}
      <PutawayTasksTable
        inv_inventoryDateLabel={inv_inventoryDateLabel}
        pageLimit={pageLimit}
        selectedRow={selectedRow}
        handleSelectRow={handleSelectRow}
      />

      {selectedRow ? (
        <Box>
          <ProductCard.Root
            productName={selectedRow.product.name}
            imageFileName={selectedRow.product.imageFilename}
            bottomSection={
              <ProductFieldsContainer>
                <Stack direction="column" gap={2}>
                  <LotNumberField
                    isRequired={selectedRow?.product.isLotNumberRequired}
                  />
                  <InventoryDateField
                    inventoryDate={dayjs(inventoryDate)}
                    inv_inventoryDateLabel={inv_inventoryDateLabel}
                    isDateValid={isInventoryDateValid}
                    setInventoryDate={(date) =>
                      dispatch(setInventoryDate(date?.toDate() || null))
                    }
                  />
                </Stack>
                <QuantityField selectedRow={selectedRow} />
              </ProductFieldsContainer>
            }
            disableGutters
          >
            <ProductCard.Sku sku={selectedRow.product.sku} />
            <ProductCard.UpcList upcs={[selectedRow.product.upc]} />
          </ProductCard.Root>

          {shouldShowGetNewBinButton && (
            <AlertBanner severity="error" sx={{ mt: 3 }}>
              {t("get new bin error message")}
            </AlertBanner>
          )}

          {shouldShowMaxQtyWarningBanner && (
            <AlertBanner severity="warning" sx={{ mt: 3 }}>
              {t("max quantity exceeded warning", {
                quantity: selectedBinCubingData?.maxFillQuantity || "unknown"
              })}
            </AlertBanner>
          )}
        </Box>
      ) : (
        <ProductCard.Loading marginTop="0" height="579px" disableGutters />
      )}

      {/* Inventory buttons (bottom row) */}
      <Stack justifySelf="end" flexDirection="row" gap={3}>
        {shouldShowActionButtons && (
          <>
            {/* Inventory Problem button */}
            <Button
              data-testid="under-receive-button"
              variant="subtle"
              size="large"
              onClick={onUnderReceivedClick}
              disabled={!selectedRow || !siteWorkstation}
            >
              {t("under received")}
            </Button>
            <Button
              data-testid="over-receive-button"
              variant="subtle"
              size="large"
              onClick={onOverReceivedClick}
              disabled={!selectedRow || !siteWorkstation}
            >
              {t("over received")}
            </Button>
            {/* Inventory Exceptions button (aka Problem Solve)  */}
            <Button
              data-testid="inventory-hold-button"
              variant="subtle"
              size="large"
              startIcon={<ErrorOutlineIcon />}
              onClick={handleClickPlaceInventoryHold}
              disabled={!isPlaceHoldEnabled}
            >
              {t("inventory hold")}
            </Button>
          </>
        )}
      </Stack>

      {shouldShowGetNewBinButton ? (
        <GetNewBinButton onSuccess={onGetNewBinSuccess} />
      ) : (
        <ConfirmInductionButton />
      )}

      {/* Modals */}
      <InventoryHoldModal
        isOpen={isInventoryHoldModalOpen}
        onClose={() => setIsInventoryHoldModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        currentSelectedBin={currentSelectedBin}
      />
      <FlagBinModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        shouldCloseBinUponSubmit
      />
      <ChangeSuggestedBinModal
        allowCompartmentSelection={false}
        isOpen={changeSuggestedBinModalOpen}
        onClose={setChangeSuggestedBinModalOpen}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        workstation={siteWorkstation}
        currentSelectedBin={currentSelectedBin}
        onConfirm={onConfirmChangeSuggestedBin}
        currentSelectedCompartment={null}
        modalTitle={t("select new bin")}
        allowSelectingCompartmentsWithoutInventoryOnly={false}
      />

      <Dialog
        onClose={() => {
          setIsBinNotEmptyPanelOpen(false);
          setNewSelectedBin(null);
          dispatch(selectNewCompartment(null));
          dispatch(clearSelectedVariant());
        }}
        open={isBinNotEmptyPanelOpen}
        maxWidth="tablet"
      >
        {newSelectedCompartment != null && (
          <BinNotEmptyModal
            portId={newSelectedBin?.openBinResponse.portId}
            binId={
              newSelectedBin?.autostoreBinCompartments.find(
                (comp) =>
                  comp.autostoreCompartmentNumber === newSelectedCompartment + 1
              )?.binId
            }
            setIsBinNotEmptyPanelOpen={setIsBinNotEmptyPanelOpen}
            onSuccess={onBinNotEmptySuccess}
          />
        )}
      </Dialog>
      <BinNotEmptyCompartment
        isOpen={isBinNotEmptyCompartmentPanelOpen}
        setIsBinNotEmptyCompartmentPanelOpen={
          setIsBinNotEmptyCompartmentPanelOpen
        }
        setIsBinNotEmptyPanelOpen={setIsBinNotEmptyPanelOpen}
        workstation={siteWorkstation}
        nextEmptyBinByPort={nextEmptyBinByPort}
        portStateByPort={portStateByPort}
        newSelectedBin={newSelectedBin}
        setNewSelectedBin={setNewSelectedBin}
        newSelectedCompartment={newSelectedCompartment}
        setNewSelectedCompartment={(compartment) =>
          dispatch(selectNewCompartment(compartment))
        }
      />
      <ScanningIndicator
        scanState={scanState}
        scannedBarcode={lastScannedBarcode}
        placeholderText="Scan Product"
      />
      <Dialog
        open={isUnderReceivedModalOpen}
        onClose={() => setIsUnderReceivedModalOpen(false)}
      >
        {isUnderReceivedModalOpen && selectedRow && (
          <AdjustQuantityModal
            putawayTask={selectedRow}
            closeModal={() => setIsUnderReceivedModalOpen(false)}
            handleSuccess={handleAdjustQuantityModalSuccess}
            variant="under-received"
          />
        )}
      </Dialog>
      <Dialog
        open={isOverReceivedModalOpen}
        onClose={() => setIsOverReceivedModalOpen(false)}
      >
        {isOverReceivedModalOpen && selectedRow && (
          <AdjustQuantityModal
            putawayTask={selectedRow}
            closeModal={() => setIsOverReceivedModalOpen(false)}
            handleSuccess={handleAdjustQuantityModalSuccess}
            variant="over-received"
          />
        )}
      </Dialog>
    </Container>
  );
};
