import { AlertProps } from "@mui/material/Alert";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  ClientConfig,
  defaultClientConfig,
  getClientConfig
} from "~/config/clientConfig";
import { loginTest } from "~/features/login/login.slice";
import { AuthCallbackHandled } from "~/features/login/login.slice.interfaces";
import { generateUuid } from "~/lib/helpers";

import { LanguageCode } from "~/localization_i18n";
import {
  ClearSingleUserMessageAction,
  ClearUserMessageAction,
  SetClientConfigAction,
  SetLanguageAction,
  SetUserMessageAction
} from "~/redux/actions/site";

export type MessageComponentType = "single" | "multiple";

export type UserMessage = {
  componentType?: MessageComponentType;
  title: string | null;
  description?: string;
  severity: AlertProps["severity"];
  id?: string | null;
  timestamp?: number | null;
  autohideDuration?: number | null;
  origin?: string | null;
  /** @deprecated */
  withMask?: boolean;
};

export type SiteState = {
  clientIds: Guid[];
  clientConfig: ClientConfig;
  loginPath: string;
  customLoginPath?: string;
  languageCode: LanguageCode;
  userMessages: UserMessage[] | [];
  singleUserMessage: UserMessage | null;
};

const initialState = {
  clientIds: [],
  clientConfig: defaultClientConfig,
  loginPath: "",
  languageCode: "" as LanguageCode,
  userMessages: [],
  singleUserMessage: null
};

type Actions =
  | ClearUserMessageAction
  | ClearSingleUserMessageAction
  | SetUserMessageAction
  | SetClientConfigAction
  | SetLanguageAction
  | AuthCallbackHandled
  | ReturnType<typeof loginTest>;

const siteReducer = (
  state: SiteState | undefined,
  action: Actions
): SiteState => {
  if (state === undefined) {
    return initialState;
  }

  switch (action.type) {
    case "login/loginTest": {
      const userProfile = action.payload;

      const { login_path, custom_login_path } = userProfile;

      const clientIds = userProfile["/client_ids"] || [];

      const firstClientId = clientIds.length ? clientIds[0] : null;

      const clientConfig = getClientConfig(firstClientId);

      return {
        ...state,
        clientIds,
        clientConfig,
        languageCode: clientConfig.site_defaultLanguageCode,
        loginPath: login_path,
        customLoginPath: custom_login_path
      };
    }

    case "login/handleAuthCallback/fulfilled": {
      const userProfile = action.payload;

      const { login_path, custom_login_path } = userProfile;

      const clientIds = userProfile["/client_ids"] || [];

      const firstClientId = clientIds.length ? clientIds[0] : null;

      const clientConfig = getClientConfig(firstClientId);

      return {
        ...state,
        clientIds,
        clientConfig,
        languageCode: clientConfig.site_defaultLanguageCode,
        loginPath: login_path,
        customLoginPath: custom_login_path
      };
    }

    case "site/SET_CLIENT_CONFIG": {
      return {
        ...state,
        clientConfig: action.payload
      };
    }

    case "site/SET_LANGUAGE":
      return {
        ...state,
        languageCode: action.payload
      };

    case "site/SET_USER_MESSAGE": {
      const {
        componentType,
        title,
        description,
        severity,
        id,
        timestamp,
        autohideDuration,
        origin,
        withMask
      } = action.payload;

      if (componentType && componentType === "single") {
        return {
          ...state,
          singleUserMessage: action.payload
        };
      }
      return {
        ...state,
        userMessages: state.userMessages.some(
          (messageObj) => messageObj.title?.trim() === title?.trim()
        )
          ? state.userMessages
          : [
              ...state.userMessages,
              {
                title,
                description,
                severity,
                id: id || generateUuid(),
                timestamp: timestamp || new Date().getTime(),
                autohideDuration:
                  autohideDuration ||
                  (severity === "error" || severity === "warning"
                    ? 10000
                    : 3000),
                origin: origin || null,
                withMask: withMask || undefined
              }
            ]
      };
    }

    case "site/CLEAR_USER_MESSAGE":
      return {
        ...state,
        userMessages: state.userMessages.filter(
          (messageObj) => messageObj.id !== action.payload
        )
      };

    case "site/CLEAR_SINGLE_USER_MESSAGE":
      return {
        ...state,
        singleUserMessage: null
      };

    default:
      return state;
  }
};

const sitePersistConfig = {
  key: "site",
  storage,
  whitelist: ["clientIds", "clientConfig", "loginPath", "languageCode"]
};

export const site = persistReducer(sitePersistConfig, siteReducer);
