import { ErrorBoundary } from "@qubit/autoparts";
import { Route, Routes, createBrowserRouter } from "react-router-dom";

import envConstants from "~/config/envConstants";
import { Analytics } from "~/features/analytics/Analytics";
import AutostoreCycleCounts from "~/features/autostoreCycleCounts/AutostoreCycleCounts";
import AutostoreInventoryHolds from "~/features/autostoreInventoryHolds/AutostoreInventoryHolds";
import { AutostoreMain } from "~/features/autostoreMain/AutostoreMain";
import AutostorePicking from "~/features/autostorePicking/AutostorePicking";
import PickingPreviousBin from "~/features/autostorePreviousBin/AutostorePreviousBin";
import { AutostorePutawayV2 } from "~/features/autostorePutaway/AutostorePutawayV2";
import { AutostorePutawayOld } from "~/features/autostorePutawayOld/AutostorePutawayOld";
import Batch from "~/features/batch/Batch";
import { BatchCartPrep } from "~/features/batch/BatchCartPrep";
import { Batches as AdminBatches } from "~/features/batches/AdminBatches";
import { BinMaintenanceWorkstation } from "~/features/binMaintenanceWorkstation/BinMaintenanceWorkstation";
import { EscalatedBins } from "~/features/binMaintenanceWorkstation/EscalatedBins";
import { BinReconfiguration } from "~/features/binReconfiguration/BinReconfiguration";
import { StartBinReconfiguration } from "~/features/binReconfiguration/StartBinReconfiguration";
import { Carts } from "~/features/carts/Carts";
import CleaningBin from "~/features/cleaningBin/CleaningBin";
import CleaningBinView from "~/features/cleaningBin/CleaningBinView";
import { CreateBatches } from "~/features/createBatches/CreateBatches";
import CycleCount from "~/features/cycleCounts/CycleCount";
import { CycleCounts } from "~/features/cycleCounts/CycleCounts";
import CycleCountTask from "~/features/cycleCounts2023/CycleCountTask";
import CycleCountsV2 from "~/features/cycleCounts2023/CycleCountsV2";
import { DemoPage } from "~/features/demoPage/DemoPage";
import { ManualOps } from "~/features/home/ManualOps";
import { AutostoreInventory } from "~/features/inventory/AutostoreInventory";
import { InventoryHolds } from "~/features/inventoryHolds/InventoryHolds";
import AuthCallback from "~/features/login/AuthCallback";
import { BevClientLogin } from "~/features/login/BevClientLogin";
import { Login } from "~/features/login/Login";
import { Logout } from "~/features/login/Logout";
import OrganizationLogin from "~/features/login/OrganizationLogin";
import LowInventory from "~/features/lowInventory/LowInventory";
import ManageFlaggedInventory from "~/features/manageFlaggedInventory/ManageFlaggedInventory";
import ManualInventory from "~/features/manualInventory/ManualInventory";
import { OrderCreation } from "~/features/orderCreation/OrderCreation";
import { Orders } from "~/features/orders/Orders";
import { PickBatches } from "~/features/pickBatches/PickBatches";
import Placeholder from "~/features/placeholder/Placeholder";
import { Putaway } from "~/features/putaway/Putaway";
import { PutawayTask } from "~/features/putaway/PutawayTask";
import ServiceSupportView from "~/features/serviceSupport/ServiceSupportView";
import WorkstationDrillDownView from "~/features/serviceSupport/adminSummaries/WorkstationDrillDownView";
import SettingsView from "~/features/settings/SettingsView";
import { Ship } from "~/features/ship/Ship";
import { ShipOrderTable } from "~/features/shipOrder/ShipOrderTable";
import { StageTotes } from "~/features/stage/StageTotes";
import useUnauthorizedRedirect from "~/hooks/useUnauthorizedRedirect";
import { isHomePageTypeIncluded } from "~/lib/helpers";
import {
  selectInterceptorReady,
  selectFlagsReadyClientId
} from "~/redux/selectors/appSelectors";
import { selectUserIsAdmin } from "~/redux/selectors/authSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";

import { AppProviders } from "./AppProviders";

import { AutostoreLayout } from "./AutostoreLayout";
import { BasicLayout } from "./BasicLayout";
import { ContextReset } from "./ContextReset";
import { LoginLayout } from "./LoginLayout";
import { PrivateRoute } from "./PrivateRoute";
import { RedirectToAutostoreMain } from "./RedirectToAutostoreMain";
import { useAppSelector } from "./store";

export const Root = () => {
  const clientConfig = useAppSelector(selectClientConfig);
  const {
    dev_orderCreation,
    binCleaningEnabled,
    fc_autostore_homepages,
    manualOpsEnabled
  } = clientConfig;
  const isAdmin = useAppSelector(selectUserIsAdmin);
  useUnauthorizedRedirect();

  const isInterceptorReady = useAppSelector(selectInterceptorReady);
  const isLdClientReady = useAppSelector(selectFlagsReadyClientId);

  // contains routes that use the <BasicLayout> component for rendering the shell and navbar
  const basicLayoutRoutes = [
    {
      path: "/",
      component: (
        <RedirectToAutostoreMain>
          <ManualOps viewTitle="nav.viewname.manual ops" />
        </RedirectToAutostoreMain>
      )
    },
    {
      path: "/admin-batches",
      component: <AdminBatches viewTitle="nav.viewname.admin batches" />
    },
    {
      path: "/batches/:batchName/cart-prep",
      component: <BatchCartPrep viewTitle="nav.viewname.cart prep" />
    },
    {
      path: "/batches/:batchId/pick",
      component: <Batch viewTitle="nav.viewname.batch - pick tasks" />
    },
    {
      path: "/batches/:batchName/stage",
      component: <StageTotes viewTitle="nav.viewname.batches - staging areas" />
    },
    {
      path: "/carts",
      component: <Carts viewTitle="nav.viewname.carts" />
    },
    {
      path: "/create-batches",
      component: <CreateBatches viewTitle="nav.viewname.create batches" />
    },
    {
      path: "/cycle-count/:cycleCountId",
      component: <CycleCount viewTitle="nav.viewname.cycle count" />
    },
    {
      path: "/cycle-count",
      component: <CycleCounts viewTitle="nav.viewname.cycle counts" />
    },
    {
      path: "/demo",
      component: <DemoPage viewTitle="nav.viewname.autostore" />
    },
    {
      path: "/analytics",
      component: <Analytics viewTitle="nav.viewname.analytics" />
    },
    {
      path: "/low-inventory",
      component: <LowInventory viewTitle="nav.viewname.low inventory" />
    },
    {
      path: "/orders",
      component: <Orders viewTitle="nav.viewname.orders" />
    },
    {
      path: "/pick-batches",
      component: <PickBatches viewTitle="nav.viewname.pick batches" />
    },
    {
      path: "/putaway",
      component: <Putaway viewTitle="nav.viewname.putaway task_plural" />
    },
    {
      path: "/settings",
      component: <SettingsView viewTitle="nav.viewname.settings" />
    },
    {
      path: "/ship/consolidation",
      component: <Ship viewTitle="nav.viewname.consolidation" />
    },
    {
      path: "/ship/order/:orderId",
      component: <ShipOrderTable viewTitle="nav.viewname.order" />
    },
    {
      path: "/users",
      component: <Placeholder viewTitle="nav.viewname.users" />
    },
    ...(isAdmin
      ? [
          {
            path: "/service-support",
            component: (
              <ServiceSupportView viewTitle="nav.viewname.service and support" />
            )
          }
        ]
      : []),
    ...(isAdmin
      ? [
          {
            path: "/admin-summaries-workstation-view",
            component: (
              <WorkstationDrillDownView viewTitle="nav.viewname.admin summaries workstation state view" />
            )
          }
        ]
      : [])
  ];

  const autostoreLayoutRoutes = [
    ...(isHomePageTypeIncluded(fc_autostore_homepages, "cycle counts")
      ? [
          {
            path: "/autostore-cyclecount",
            component: (
              <AutostoreCycleCounts viewTitle="nav.viewname.autostore cyclecounts" />
            )
          }
        ]
      : []),
    {
      path: "/autostore-inventory-holds",
      component: (
        <AutostoreInventoryHolds viewTitle="nav.viewname.autostore inventory holds" />
      )
    },
    {
      path: "/autostore-main",
      component: <AutostoreMain viewTitle="nav.viewname.autostore" />
    },
    {
      path: "/autostore-pick",
      component: <AutostorePicking viewTitle="nav.viewname.autostore pick" />
    },
    ...(isHomePageTypeIncluded(fc_autostore_homepages, "induction v2")
      ? [
          {
            path: "/autostore-putaway-v2",
            component: (
              <AutostorePutawayV2 viewTitle="nav.viewname.autostore induction" />
            )
          }
        ]
      : [
          {
            path: "/autostore-putaway",
            component: (
              <AutostorePutawayOld viewTitle="nav.viewname.autostore induction" />
            )
          }
        ]),
    ...(manualOpsEnabled
      ? [
          {
            path: "/manual-inventory",
            component: <ManualInventory viewTitle="nav.viewname.inventory" />
          },
          {
            path: "/manual-inventory/product",
            component: <ManualInventory viewTitle="nav.viewname.inventory" />
          },
          {
            path: "/manual-inventory/product/:variantId",
            component: (
              <ManualInventory viewTitle="nav.viewname.inventory control - product" />
            )
          },
          {
            path: "/manual-inventory/bin",
            component: <ManualInventory viewTitle="nav.viewname.inventory" />
          },
          {
            path: "/manual-inventory/bin/:binId",
            component: (
              <ManualInventory viewTitle="nav.viewname.inventory control - bin" />
            )
          }
        ]
      : []),
    {
      path: "/inventory-holds",
      component: <InventoryHolds viewTitle="nav.viewname.inventory holds" />
    },
    {
      path: "/picking-previous-bin",
      component: <PickingPreviousBin />
    },
    {
      path: "/putaway/:variantId",
      component: <PutawayTask viewTitle="nav.viewname.putaway task" />
    },
    {
      path: "/start-bin-reconfiguration",
      component: (
        <StartBinReconfiguration viewTitle="nav.viewname.start bin reconfiguration" />
      )
    },
    {
      path: "/bin-reconfiguration",
      component: (
        <BinReconfiguration viewTitle="nav.viewname.bin reconfiguration" />
      )
    }
  ];

  autostoreLayoutRoutes.push(
    {
      path: "/autostore-cyclecountv2",
      component: <CycleCountsV2 />
    },
    {
      path: "/autostore-cyclecountv2/cycle-count-task",
      component: <CycleCountTask />
    }
  );

  if (binCleaningEnabled) {
    autostoreLayoutRoutes.push({
      path: "/bin-maintenance",
      component: <CleaningBin viewTitle="nav.viewname.bin maintenance" />
    });
    autostoreLayoutRoutes.push({
      path: "/view-bin-maintenance",
      component: <CleaningBinView viewTitle="nav.viewname.bin maintenance" />
    });
  }

  //autostore-inventory routes
  autostoreLayoutRoutes.push(
    {
      path: "/autostore-inventory/holds",
      component: <AutostoreInventory viewTitle="nav.viewname.inventory holds" />
    },
    {
      path: "/autostore-inventory",
      component: <AutostoreInventory viewTitle="nav.viewname.inventory" />
    },
    {
      path: "/autostore-inventory/product",
      component: <AutostoreInventory viewTitle="nav.viewname.inventory" />
    },
    {
      path: "/autostore-inventory/product/:variantId",
      component: (
        <AutostoreInventory viewTitle="nav.viewname.inventory control - product" />
      )
    },
    {
      path: "/autostore-inventory/bin",
      component: <AutostoreInventory viewTitle="nav.viewname.inventory" />
    },
    {
      path: "/autostore-inventory/bin/:binNumber",
      component: (
        <AutostoreInventory viewTitle="nav.viewname.inventory control - bin" />
      )
    },
    {
      path: "/autostore-inventory/manage-flagged",
      component: (
        <ManageFlaggedInventory viewTitle="nav.viewname.manage flagged bins" />
      )
    }
  );

  // 3 port maintenance
  autostoreLayoutRoutes.push(
    {
      path: "/workstation-bin-maintenance",
      component: (
        <BinMaintenanceWorkstation viewTitle="nav.viewname.bin maintenance" />
      )
    },
    {
      path: "/workstation-bin-maintenance/escalated-bins",
      component: <EscalatedBins viewTitle="nav.viewname.bin maintenance" />
    }
  );

  if (envConstants.DEV_CHEATS_UAT === "true" || dev_orderCreation) {
    autostoreLayoutRoutes.push({
      path: "/order-creation",
      component: <OrderCreation viewTitle="nav.viewname.order creation" />
    });
  }

  return (
    <ErrorBoundary>
      <Routes>
        {/* login page */}
        <Route path="/login" element={<Login />} />
        <Route path="/login/:name" element={<OrganizationLogin />} />

        {/* other login related routes */}
        <Route element={<LoginLayout />}>
          <Route path="/login/pepsi" element={<BevClientLogin />} />
          <Route path="/callback" element={<AuthCallback />} />
          <Route
            path="/logout"
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />
        </Route>

        {/* these paths require interceptors and set flags */}
        {isInterceptorReady && isLdClientReady && (
          <Route element={<AppProviders />}>
            {/* routes that use the AutostoreLayout to render view and navbar */}
            <Route element={<AutostoreLayout />}>
              {autostoreLayoutRoutes.map((info) => (
                <Route
                  key={info.path}
                  path={info.path}
                  element={
                    <PrivateRoute>
                      <ContextReset key={info.path}>
                        {info.component}
                      </ContextReset>
                    </PrivateRoute>
                  }
                />
              ))}
            </Route>
            {/* routes that use the BasicLayout to render view and navbar */}
            <Route element={<BasicLayout />}>
              {basicLayoutRoutes.map((info) => (
                <Route
                  key={info.path}
                  path={info.path}
                  element={
                    <PrivateRoute>
                      <ContextReset key={info.path}>
                        {info.component}
                      </ContextReset>
                    </PrivateRoute>
                  }
                />
              ))}
            </Route>
          </Route>
        )}
      </Routes>
    </ErrorBoundary>
  );
};

export const router = createBrowserRouter([{ path: "*", element: <Root /> }], {
  future: {
    // affects relative links inside of wild card paths (ie. path/*) we have none of these.
    v7_relativeSplatPath: true,
    // affects only people using useFetcher
    v7_fetcherPersist: true,
    // affects people using useFetcher().formMethod and use navigation.formMethod
    v7_normalizeFormMethod: true,
    // affects only SSR frameworks.
    v7_partialHydration: true,
    // affects projects that user loaders
    v7_skipActionErrorRevalidation: true
  }
});
